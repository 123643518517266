<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                    <label class="vs-input--label">Chi nhánh</label>
                    <v-select class="" v-model="selectedOrganizationBranch" :clearable="false"
                              :options="organizationBranchOptions">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>
                <div class="vx-col md:w-1/4 w-full mt-2">
                    <label class="vs-input--label">Nhân viên</label>
                    <v-select class="" v-model="selectedEmployee" :clearable="false" :options="employeeOptions">
                        <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                </div>

                <div class="vx-col md:w-1/4 w-full mt-2">
                    <label class="vs-input--label">Đã check in</label>
                    <v-select class="" v-model="selectedIsCheckIn" :clearable="false" :options="isCheckInOptions"/>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col md:w-1/4 w-full mt-2">
                    <label class="vs-input--label">Đi muộn</label>
                    <v-select class="" v-model="selectedIsLate" :clearable="false" :options="isLateOptions"/>
                </div>
                <div class="vx-col mt-2">
                    <label class="vs-input--label">Thời gian: </label>
                    <div class="flex-grow">
                        <date-range-picker
                            ref="picker"
                            :locale-data="{format: 'dd/mm/yyyy' }"
                            :opens="'right'"
                            @update="dateRangePicker"
                            v-model="dateRange">
                        </date-range-picker>
                    </div>
                </div>
                <div class="vx-col md:w-1/3 w-full mt-3">
                    <vs-button class="ml-auto mt-5 mr-4" @click="filter" :disabled="!validateForm">Lọc</vs-button>
                    <vs-button class="ml-auto mt-5" @click="exportFile" :disabled="!validateForm">Tạo báo cáo
                    </vs-button>
                </div>
            </div>
            <div class="flex flex-wrap items-center mt-2">
                <div class="flex-grow">
                </div>

                <!-- ITEMS PER PAGE -->
                <Pagination
                    :itemList="workShiftData"
                    :currentPage="currentPage"
                    :paginationPageSize="paginationPageSize"
                    :gridApi="gridApi"
                />
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="workShiftData"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :localeText=" {noRowsToShow: ' '}"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import authConfig from '@/../auth_config.json';
import moment from 'moment'; // Vuesax
import Pagination from "../../ui-elements/Pagination.vue"
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Datepicker from 'vuejs-datepicker';
import DateRangePicker from '../../../components/vue2-daterange-picker/components/DateRangePicker.vue'

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        Datepicker,
        Pagination,
        CellRendererActions,
        DateRangePicker,
        flatPickr
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Mã NV',
                    field: 'employeeCode',
                    width: 100
                },
                {
                    headerName: 'Họ và tên',
                    field: 'fullName',
                    width: 200
                },
                {
                    headerName: 'Ca',
                    field: 'displayGroupName',
                    width: 200,
                },
                {
                    headerName: 'Ngày',
                    field: 'date',
                    width: 130,
                },
                {
                    headerName: 'Vào ca',
                    field: 'checkinTime',
                    width: 130,
                },
                {
                    headerName: 'Tan ca',
                    field: 'checkoutTime',
                    width: 130,
                },
                {
                    headerName: 'Loại chấm công',
                    field: 'actionTypeName',
                    width: 130,
                },
                {
                    headerName: 'Hoạt động',
                    field: 'actions',
                    width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions
            },
            employeeData: [],
            workShiftData: [],
            organizationBranchOptions: [],
            employeeOptions: [
                {value: null, label: 'Tất cả'}
            ],
            selectedOrganizationBranchValue: null,
            selectedEmployeeValue: null,
            dateRange: {
                endDate: new Date(),
                startDate: (() => {
                    let date = new Date();
                    date.setHours(0, 0, 0);
                    return date
                })()
            },
            isCheckIn: null,
            isCheckInOptions: [
                {value: null, label: 'Tất cả'},
                {value: true, label: 'Đã check in'},
                {value: false, label: 'Chưa check in'}
            ],
            isLate: null,
            isLateOptions: [
                {value: null, label: 'Tất cả'},
                {value: true, label: 'Đi muộn'}
            ],
        }
    },
    created() {
        if (this.$route.query.isCheckin && this.$route.query.isCheckin == 'true') {
            this.selectedIsCheckIn = {value: true, label: 'Đã check in'};
        } else if (this.$route.query.isCheckin && this.$route.query.isCheckin == 'false') {
            this.selectedIsCheckIn = {value: false, label: 'Chưa check in'};
        }
        if (this.$route.query.employeeId) this.selectedEmployeeValue = this.$route.query.employeeId;
        if (this.$route.query.isLate) this.selectedIsLate = {value: true, label: 'Đi muộn'};
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateCheckInCheckOut'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_CHECKIN_CHECKOUT', false);
            }
        }
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        selectedOrganizationBranch: {
            get() {
                return {
                    label: this.organizationBranchString(this.selectedOrganizationBranchValue),
                    value: this.selectedOrganizationBranchValue
                }
            },
            set(obj) {
                this.selectedOrganizationBranchValue = obj.value
                this.loadEmployee()
            }
        },
        selectedEmployee: {
            get() {
                return {
                    label: this.employeeString(this.selectedEmployeeValue),
                    value: this.selectedEmployeeValue
                }
            },
            set(obj) {
                this.selectedEmployeeValue = obj.value
            }
        },
        selectedIsCheckIn: {
            get() {
                return {
                    label: this.isCheckinString(this.isCheckIn),
                    value: this.isCheckIn
                }
            },
            set(obj) {
                this.isCheckIn = obj.value
            }
        },
        selectedIsLate: {
            get() {
                return {
                    label: this.isLateString(this.isLate),
                    value: this.isLate
                }
            },
            set(obj) {
                this.isLate = obj.value
            }
        }
    },
    methods: {
        dateRangePicker: function () {
            this.dateRange.startDate = new Date(this.dateRange.startDate.setHours(0, 0, 0));
            this.dateRange.endDate = new Date(this.dateRange.endDate.setHours(23, 59, 59));
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || 'xác định';
        },
        employeeString(id) {
            let employee = this.employeeOptions.find(x => x.value === id);
            return (employee && employee.label) || 'Chưa xác định';
        },
        isCheckinString(id) {
            let isCheckIn = this.isCheckInOptions.find(x => x.value === id);
            return (isCheckIn && isCheckIn.label) || 'Chưa xác định';
        },
        isLateString(id) {
            let isLate = this.isLateOptions.find(x => x.value === id);
            return (isLate && isLate.label) || 'Chưa xác định';
        },
        addEmployee() {
            this.$router.push(`/user/add-employee?branch_id=${this.selectedOrganizationBranchValue}`).catch(() => {
            });
        },
        validateForm() {
            return !this.errors.any()
        },
        filter() {
            let searchInfo = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                employeeId: this.selectedEmployee.value ? [this.selectedEmployee.value] : null,
                start: moment(this.dateRange.startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                end: moment(this.dateRange.endDate).set({hour: 23, minute: 59, second: 59, millisecond: 59}),
                isCheckIn: this.isCheckIn,
                isLate: this.isLate
            }
            this.$crm.post('/time-keeping/find', searchInfo).then((response) => {
                let workShiftData = response.data.sort((item1, item2) => {
                    if (moment(item1.date) > moment(item2.date)) return 1;
                    if (moment(item1.date) < moment(item2.date)) return -1;
                    // return item1.employeeCode > item2.employeeCode ? 1 : -1
                })
                this.workShiftData = workShiftData.map(item => {
                    item.displayGroupName = item.groupName + " - " + item.workShiftName;
                    let employee = this.employeeOptions.find(x => x.employeeCode == item.employeeCode);
                    item.employeeId = employee ? employee.value : null;

                    item.branchId = this.selectedOrganizationBranchValue;
                    item.actionTypeName = '';
                    if (item.actionType != null) {
                        switch (item.actionType) {
                            case 0:
                                item.actionTypeName = 'CameraAI';
                                break;
                            case 1:
                                item.actionTypeName = 'Wifi';
                                break;
                            case 2:
                                item.actionTypeName = 'QR Code';
                                break;
                            case 3:
                                item.actionTypeName = 'Quản lý duyệt';
                                break;
                            case 4:
                                item.actionTypeName = 'Khiếu nại chấm công';
                                break;
                            default:
                                break;
                        }
                    }
                    return item;
                })
                this.workShiftData = this.workShiftData.filter(x => x.employeeId);
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        exportFile() {
            let searchInfo = {
                organizationBranchId: this.selectedOrganizationBranchValue,
                employeeId: this.selectedEmployee.value ? [this.selectedEmployee.value] : null,
                start: moment(this.dateRange.startDate).set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                end: moment(this.dateRange.endDate).set({hour: 23, minute: 59, second: 59, millisecond: 59}),
                isCheckIn: this.isCheckIn,
                isLate: this.isLate
            };

            this.$vs.loading();
            this.$crm.post('/time-keeping/create', searchInfo).then((response) => {
                this.$vs.loading.close();
                window.open(authConfig.crmDomain + "file/" + response.data.fileName + "?access_token=" + localStorage.getItem("access_token"), "_blank");
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]')
            if (branchList.length == 0) {
                return this.$vs.notify({
                    text: 'Hiện tại không tồn tại chi nhánh nào',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
            this.organizationBranchOptions = branchList.map(item => {
                return {value: item.id, label: item.organizationBranchName}
            })
            let branchId = this.$route.query.branch_id;
            let index = 0;
            if (branchId) {
                index = this.organizationBranchOptions.findIndex(item => item.value == branchId)
            }
            this.selectedOrganizationBranch = this.organizationBranchOptions[index > -1 ? index : 0];
            this.filter()
        },
        loadEmployee() {
            if (!this.selectedOrganizationBranchValue) return;

            this.$vs.loading();
            this.$crm.post('/employee/find-by-organization-branch/' + this.selectedOrganizationBranchValue).then((response) => {
                this.employeeData = response.data;
                this.employeeOptions = response.data.map(item => {
                    return {
                        value: item.employeeId,
                        label: item.employeeCode + " " + item.fullName,
                        employeeCode: item.employeeCode
                    }
                });
                if (this.employeeOptions.length > 0 && this.selectedEmployeeValue) {
                    let employee = this.employeeOptions.find(x => x.value == this.selectedEmployeeValue);
                    this.selectedEmployee = employee;
                }
                this.employeeOptions.unshift({value: null, label: 'Tất cả'})
                setTimeout(() => {
                    this.gridApi.sizeColumnsToFit();
                }, 180);
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
